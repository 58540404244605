import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import PostCalcV2 from './PostCalcV2';
import PostCalcV1 from './PostCalcV1';
import PostCalcV3 from './PostCalcV3';


ReactDOM.render(<div><PostCalcV1 /><PostCalcV2 /><PostCalcV3 /></div>, document.getElementById('root'));
