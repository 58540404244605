/* eslint-disable @typescript-eslint/no-inferrable-types */

// Scope of AAD app. Use the below configuration to use all the permissions provided in the AAD app through Azure portal.
// Refer https://aka.ms/PowerBIPermissions for complete list of Power BI scopes
export const scopes: string[] = ["https://analysis.windows.net/powerbi/api/Report.Read.All"];

// Client Id (Application Id) of the AAD app.
export const clientId: string= "f7bf9d32-f9ab-4999-aed9-8958e55eb4bb";

// Id of the workspace where the report is hosted
export const workspaceId: string = "536aa666-4e08-4052-83f5-8faa0a1b543d";

// Id of the report to be embedded
export const reportId: string = "0a13fc2d-2554-4ce8-adef-2032d2849e76";